<template>
  <div class="qikan">
    <div class="cont">
      <div class="cont_f">
        <div class="cover">
          <img class="cover" src="https://ht.yuicloud.com/web_page/magazine_website_english.png" alt="" />
        </div>
        <div class="information">
          <span class="title">河南医学研究（英文版）</span>
          <div class="detailed">
            <div class="first">
              <span>ISSN：2616-4884</span>
              <span>创刊时间：2018年</span>
              <span>主管方：河南省医学科学院</span>
              <span>主办方：国际期刊联盟</span>
              <span>分类：临床医学</span>
              <span>语言：英文</span>
              <span>周期：季刊</span>
              <span>收录网站：超星</span>
            </div>
            <!-- <div class="">
							<span>邮政编码：</span>
							<span>电&emsp;&emsp;话：</span>
							<span>电子信箱：</span>
							<span>国际标准连续出版物号：</span>
							<span>国内统一连续出版物号：</span>
						</div> -->
          </div>
          <!-- <span class="introduction"><span style="width: 36px;display: inline-block;"></span>这是一个简介《河南医学研究》杂志创刊于1992年，主管单位为河南省卫生健康委员会，主办单位为河南省医学科学院，旬刊，大16开。期刊的发行对象是全国各级医疗机构的医务人员。办刊宗旨：坚持正确的舆论导向，把社会效益放在首位，严格执行学术期刊的编辑、出版标准、打造医学学术交流平台。</span> -->
        </div>
      </div>
      <!-- <div class="cont_s">
				<div class="stitle">
					<img class="simg" src="../static/index/lanmu.png" alt="">
					<span>栏目设置</span>
				</div>
				<span class="column"><span style="width: 36px;display: inline-block;"></span>专题报道、专家述评、传统医药、基础研究、临床研究、论著、经验交流、护理实践、药学、预防研究、卫生管理、案例报道、综述、医学教育、中西医结合、专利技术、科研管理、医院管理、药械管理等。</span>
			</div>
			<div class="cont_t">
				<div class="stitle">
					<img class="simg" src="../static/index/qikan.png" alt="">
					<span>期刊收录</span>
				</div>
				<div class="Included">
					<div class="sec">
						<span>中国学术期刊综合评价数据库（CNKI）统计源期刊</span>
						<span>中国核心期刊（遴选）数据库收录期刊</span>
						<span>中国学术期刊（光盘版）全文收录期刊</span>
						<span>CA化学文摘（美）收录期刊</span>
					</div>
					<div class="">
						<span>河南省医学三类学术期刊</span>
						<span>中国期刊网收录期刊</span>
						<span>中国国家图书馆入藏</span>
						<span>万方数据库收录期刊</span>
					</div>
				</div>
			</div> -->
    </div>

    <div class="cont">
      <div class="cont_f">
        <div class="cover">
          <img class="cover" src="https://ht.yuicloud.com/web_page/magazine_website_kf.png" alt="" />
        </div>
        <div class="information">
          <span class="title">国际康复医学</span>
          <div class="detailed">
            <div class="first">
              <span>ISSN：2616-5953</span>
              <span>创刊时间：2018年</span>
              <span>主管方：河南省医学科学院</span>
              <span>主办方：国际期刊联盟</span>
              <span>分类：临床医学</span>
              <span>语言：英文</span>
              <span>周期：季刊</span>
              <span>收录网站：超星</span>
            </div>
            <!-- <div class="">
							<span>邮政编码：</span>
							<span>电&emsp;&emsp;话：</span>
							<span>电子信箱：</span>
							<span>国际标准连续出版物号：</span>
							<span>国内统一连续出版物号：</span>
						</div> -->
          </div>
          <!-- <span class="introduction"><span style="width: 36px;display: inline-block;"></span>这是一个简介《河南医学研究》杂志创刊于1992年，主管单位为河南省卫生健康委员会，主办单位为河南省医学科学院，旬刊，大16开。期刊的发行对象是全国各级医疗机构的医务人员。办刊宗旨：坚持正确的舆论导向，把社会效益放在首位，严格执行学术期刊的编辑、出版标准、打造医学学术交流平台。</span> -->
        </div>
      </div>
      <!-- <div class="cont_s">
				<div class="stitle">
					<img class="simg" src="../static/index/lanmu.png" alt="">
					<span>栏目设置</span>
				</div>
				<span class="column"><span style="width: 36px;display: inline-block;"></span>专题报道、专家述评、传统医药、基础研究、临床研究、论著、经验交流、护理实践、药学、预防研究、卫生管理、案例报道、综述、医学教育、中西医结合、专利技术、科研管理、医院管理、药械管理等。</span>
			</div>
			<div class="cont_t">
				<div class="stitle">
					<img class="simg" src="../static/index/qikan.png" alt="">
					<span>期刊收录</span>
				</div>
				<div class="Included">
					<div class="sec">
						<span>中国学术期刊综合评价数据库（CNKI）统计源期刊</span>
						<span>中国核心期刊（遴选）数据库收录期刊</span>
						<span>中国学术期刊（光盘版）全文收录期刊</span>
						<span>CA化学文摘（美）收录期刊</span>
					</div>
					<div class="">
						<span>河南省医学三类学术期刊</span>
						<span>中国期刊网收录期刊</span>
						<span>中国国家图书馆入藏</span>
						<span>万方数据库收录期刊</span>
					</div>
				</div>
			</div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: "qikan",
  components: {},
  data() {
    return {};
  },
};
</script>
<style lang="scss">
.qikan {
  width: 1182px;
  margin: 50px auto 273px;
  display: flex;
  justify-content: space-between;
  .cont {
    .cont_f {
      display: flex;
      .cover {
        width: 214px;
        height: 302px;
        margin-right: 24px;
      }
      .information {
        .title {
          font-size: 26px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #222222;
        }
        .detailed {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          display: flex;
          margin: 18px 0;
          .first {
            width: 247px;
            margin-right: 24px;
          }
          span {
            display: block;
            line-height: 32px;
          }
        }
        .introduction {
          width: 940px;
          height: 96px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          line-height: 32px;
        }
      }
    }
    // .cont_s{
    // 	margin: 24px 0;
    // 	.stitle{
    // 		font-size: 18px;
    // 		font-family: PingFangSC-Medium, PingFang SC;
    // 		font-weight: 500;
    // 		color: #222222;
    // 		display: flex;
    // 		align-items: center;
    // 		margin-bottom: 6px;
    // 		.simg{
    // 			width: 20px;
    // 			height: 20px;
    // 			margin-right: 12px;
    // 		}
    // 	}
    // 	.column{
    // 		font-size: 16px;
    // 		font-family: PingFangSC-Regular, PingFang SC;
    // 		font-weight: 400;
    // 		color: #222222;
    // 		line-height: 32px;
    // 		margin-left: 32px;
    // 		display: block;
    // 	}
    // }
    // .cont_t{
    // 	.stitle{
    // 		font-size: 18px;
    // 		font-family: PingFangSC-Medium, PingFang SC;
    // 		font-weight: 500;
    // 		color: #222222;
    // 		display: flex;
    // 		align-items: center;
    // 		margin-bottom: 6px;
    // 		.simg{
    // 			width: 20px;
    // 			height: 20px;
    // 			margin-right: 12px;
    // 		}
    // 	}
    // 	.Included{
    // 		display: flex;
    // 		margin-left: 32px;
    // 		.sec{
    // 			width: 530px;
    // 		}
    // 		span{
    // 			display: block;
    // 			font-size: 16px;
    // 			font-family: PingFangSC-Regular, PingFang SC;
    // 			font-weight: 400;
    // 			color: #222222;
    // 			line-height: 32px;
    // 		}
    // 	}
    // }
  }
}
</style>